/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    a: "a",
    strong: "strong",
    h2: "h2",
    div: "div",
    hr: "hr",
    img: "img",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components), {PermaLink, Notes, Footnotes} = _components;
  if (!Footnotes) _missingMdxReference("Footnotes", true);
  if (!Notes) _missingMdxReference("Notes", true);
  if (!PermaLink) _missingMdxReference("PermaLink", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In the context of variational circuits, an ", React.createElement(_components.em, null, "ansatz"), " usually describes a subroutine consisting of a sequence of gates\napplied to specific wires. Similar to the architecture of a neural network, this only defines the base structure,\nwhile the types of gates and/or their free parameters can be optimized by the variational procedure."), "\n", React.createElement(_components.p, null, "Many variational circuit ansaetze ", React.createElement(_components.a, {
    href: "#footnote-1"
  }, "[1]"), " have been proposed by the quantum computing community. The strength\nof an ansatz depends on the desired use-case, and it is not always clear what makes a good ansatz."), "\n", React.createElement(_components.p, null, "One can distinguish three different base structures, namely a\n", React.createElement(_components.strong, null, "layered gate ansatz"), ", an ", React.createElement(_components.strong, null, "alternating operator ansatz"), ", and a ", React.createElement(_components.strong, null, "tensor network ansatz"), "."), "\n", React.createElement(_components.h2, {
    id: "layered-gate-architectures-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#layered-gate-architectures-",
    "aria-label": "layered gate architectures  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Layered gate architectures ", React.createElement(PermaLink, {
    title: "Layered gate architectures"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.em, null, "layer"), " is a sequence of gates that is repeated. The number of repetitions\nof a layer forms a hyperparameter of the variational circuit."), "\n", React.createElement(_components.p, null, "We can often decompose a layer further into two overall unitaries $A$ and $B$."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/0c70081578682e1358a7eb2898a8d533/vc_general.svg",
    alt: "VC General"
  })), "\n", React.createElement(_components.p, null, "Block $A$ contains single-wire gates applied to every subsystem or wire. Block $B$ consists of\nboth single-wire gates as well as entangling gates."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/f6e759b6807d748256f8c7aec014e2af/vc_gatearchitecture.svg",
    alt: "VC Gate Architecture"
  })), "\n", React.createElement(_components.p, null, "Layered gate ansaetze can differ in three regards:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Whether only $A$, only $B$, or both $A$ and $B$ are parametrized"), "\n", React.createElement(_components.li, null, "Which types of gates are used in $A$ and $B$"), "\n", React.createElement(_components.li, null, "Whether the gates in Block $B$ are arranged randomly, fixed, or determined by a hyperparameter"), "\n"), "\n", React.createElement(_components.p, null, "Such layered ansaetze appear in both discrete and continuous-variable quantum computing models."), "\n", React.createElement(_components.h3, {
    id: "a-parametrized-b-fixed-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-parametrized-b-fixed-",
    "aria-label": "a parametrized b fixed  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A parametrized, B fixed ", React.createElement(PermaLink, {
    title: "A parametrized, B fixed"
  })), "\n", React.createElement(_components.p, null, "In the simplest case of qubit-based devices, we can use general SU(2) gates (i.e., rotations) $R$ in\nBlock $A$ and let $B$ be fixed."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/7cd3ae418476958b26a22d8404517d58/vc_staticent.svg",
    alt: "vc staticent"
  })), "\n", React.createElement(_components.h3, {
    id: "a-parametrized-b-parametrized-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-parametrized-b-parametrized-",
    "aria-label": "a parametrized b parametrized  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A parametrized, B parametrized ", React.createElement(PermaLink, {
    title: "A parametrized, B parametrized"
  })), "\n", React.createElement(_components.p, null, "We can also have both $A$ and $B$ parametrized and the arrangements of the two-qubit gates\ndepends on a hyperparameter defining the range of two-qubit\ngates (see also ", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1612.02806"
  }, "Romero, Olson and Aspuru-Guzik (2016)"), ",\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1804.00633"
  }, "Schuld et al. (2018)"), ")."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/f90cd93b87348ff5d99a5f6f8fa986c2/vc_cc.svg",
    alt: "vc cc"
  })), "\n", React.createElement(_components.p, null, "A fully parametrized architecture specific to continuous-variable systems has been proposed in\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1803.07128"
  }, "Schuld & Killoran (2018)"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/a5740f723b00dc8d2f34ed0b00f987e8/vc_cvkernels.svg",
    alt: "vc cv kernels"
  })), "\n", React.createElement(_components.p, null, "The entangling layer $B$ contains an interferometer, a passive optical circuit made up of individual\nbeamsplitters and phase shifters. Block $A$ consists of single-mode gates with consecutively higher\norder for the quadrature operator $\\hat{x}$ which generates the gate: the displacement gate $D$\nis order-1, the quadratic phase gate $Q$ is order-2, and the cubic phase gate $V$ is order-3."), "\n", React.createElement(_components.h3, {
    id: "a-fixed-b-parametrized-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-fixed-b-parametrized-",
    "aria-label": "a fixed b parametrized  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A fixed, B parametrized ", React.createElement(PermaLink, {
    title: "A fixed, B parametrized"
  })), "\n", React.createElement(_components.p, null, "An example where the single-qubit gates are fixed is a so-called ", React.createElement(_components.em, null, "Instantaneous Quantum Polynomial (IQP)"), "\ncircuit, where $A$ consists of Hadamard gates and $B$ is made up of parametrized diagonal\none- and two-qubit gates (", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/0809.0847"
  }, "Shepherd & Bremner (2008)"), ",\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1804.11326"
  }, "Havlicek et al. (2018)"), ")."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/bff2a1adae8ef6b3aa7a264012dd580d/vc_iqp.svg",
    alt: "vc iqp"
  })), "\n", React.createElement(_components.p, null, "Analogous circuits can also be considered for continuous-variable systems\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1712.07288"
  }, "Arrazola, Rebentrost and Weedbrook (2017)"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/5cbef7a45997d5d9640438f62181b7f9/vc_iqp_cv.svg",
    alt: "vc iqp cv"
  })), "\n", React.createElement(_components.p, null, "IQP circuits are structured so that all gates in the $B$ block are diagonal in the computational basis."), "\n", React.createElement(_components.h3, {
    id: "other-structures-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#other-structures-",
    "aria-label": "other structures  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Other structures ", React.createElement(PermaLink, {
    title: "Other structures"
  })), "\n", React.createElement(_components.p, null, "Generalizing the simple two-block structure allows to build more complex layers, such as this layer of a\nphotonic neural network which emulates how information is processed in classical neural\nnets (", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1806.06871"
  }, "Killoran et al. (2018)"), ",\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1808.10047"
  }, "Steinbrecher et al. (2018)"), ")."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/bde8e529d8665d1a3c77c0698edfb475/vc_cvqnn.svg",
    alt: "vc cvqnn"
  })), "\n", React.createElement(_components.h2, {
    id: "alternating-operator-ansatz-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#alternating-operator-ansatz-",
    "aria-label": "alternating operator ansatz  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Alternating operator ansatz ", React.createElement(PermaLink, {
    title: "Alternating operator ansatz"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "The alternating operator ansatz was first introduced by\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1411.4028"
  }, "Farhi, Goldstone and Gutmann (2014)"), " as the\n", React.createElement(_components.em, null, "Quantum Approximate Optimization Algorithm"), " (QAOA), and later used\nfor machine learning (", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1712.05304"
  }, "Verdon, Broughton, Biamonte (2017)"), ")\nand other domain-specific applications (", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1810.13411"
  }, "Fingerhuth et al. (2018)"), ")."), "\n", React.createElement(_components.p, null, "Again, we use layers of two blocks. The difference is that this time the unitaries representing\nthese blocks are defined via Hamiltonians $A$ and $B$ which are evolved for a short time $\\Delta t$."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/decb268b55c67e97992f4575eeac58af/vc_aoa.svg",
    alt: "vc aoa"
  })), "\n", React.createElement(_components.p, null, "The idea of this ansatz is based on analogies to adiabatic quantum computing, in which the system starts\nin the ground state of $A$ and adiabatically evolves to the ground state of  $B$. Quickly\nalternating (i.e., ", React.createElement(_components.em, null, "stroboscopic"), ") applications of  $A$ and  $B$ for very short times $\\Delta t$\ncan be used as a heuristic to approximate this evolution."), "\n", React.createElement(_components.h2, {
    id: "tensor-network-ansatz-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tensor-network-ansatz-",
    "aria-label": "tensor network ansatz  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tensor network ansatz ", React.createElement(PermaLink, {
    title: "Tensor network ansatz"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Amongst the architectures that do not consist of layers, but a single fixed structure, are gate sequences\ninspired by tensor networks (", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1803.11537"
  }, "Huggins et al. (2018)"), ",\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1810.11922"
  }, "Du et al. (2018)"), "). The simplest one is a tree\narchitecture that consecutively entangles subsets of qubits."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/8794e055f4f07cd0ec1bfd581cfcdd74/vc_tree.svg",
    alt: "vc tree"
  })), "\n", React.createElement(_components.p, null, "Another tensor network is based on ", React.createElement(_components.em, null, "matrix product states"), ". The circuit unitaries can be decomposed in different ways,\nand their size corresponds to the \"bond dimension\" of the matrix product state — the higher the bond dimension,\nthe more complex the circuit ansatz."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/7a56197fb42b7d418fe0758f4267c178/vc_mps.svg",
    alt: "vc mps"
  })), "\n", React.createElement(Notes, {
    title: "Notes",
    type: "success",
    text: `Tensor networks such as matrix product states were invented to simulate certain quantum systems efficiently (though not universally) on classical computers. Hence, tensor network architectures do not necessarily give rise to classically intractable quantum nodes, but have found use as machine learning models ([Stoudenmire & Schwab (2016)](https://arxiv.org/abs/1605.05775)).`
  }), "\n", React.createElement(Notes, {
    title: "See also",
    type: "primary",
    text: `In PennyLane, an ansatz is called a *template*. PennyLane contains [growing library](https://docs.pennylane.ai/en/stable/introduction/templates.html#intro-ref-temp) of such circuit architectures.`
  }), "\n", React.createElement(Footnotes, {
    footnotes: [{
      text: '“Ansaetze” is the German plural for “ansatz”.',
      id: 'footnote-1'
    }]
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
